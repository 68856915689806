export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
  },
  {
    title: 'Shipments',
    route: 'shipments',
    icon: 'FileIcon',
    permissions: {
      companyUser: ['view shipment'],
      user: ['view shipment'],
    },
  },
  {
    title: 'Parcels',
    route: 'parcels',
    icon: 'PackageIcon',
    permissions: {
      companyUser: ['view shipment'],
      user: ['view shipment'],
    },
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'ActivityIcon',
    permissions: {
      companyUser: ['view report'],
      user: ['view report'],
    },
  },
  {
    title: 'Events history',
    route: 'eventsHistory',
    icon: 'ArchiveIcon',
  },
  {
    header: 'Settings',
  },
  {
    title: 'Companies',
    route: 'companies',
    permissions: {
      user: ['view company'],
    },
    icon: 'BookOpenIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    permissions: {
      companyUser: ['view user'],
      user: ['view user'],
    },
  },
  {
    title: 'Commodities',
    route: 'special-commodities',
    icon: 'LockIcon',
    permissions: {
      companyUser: ['view special-commodities'],
      user: ['view special-commodities'],
    },
  },
  {
    title: 'Warehouses',
    route: 'warehouses',
    icon: 'ArchiveIcon',
    permissions: {
      companyUser: ['view warehouse'],
      user: ['view warehouse'],
    },
  },
  {
    title: 'Carriers',
    route: 'carriers',
    icon: 'TruckIcon',
    permissions: {
      companyUser: ['view carrier'],
      user: ['view carrier'],
    },
  },
  {
    header: 'Help',
  },
  {
    title: 'Support center',
    href: 'https://feeport.atlassian.net/servicedesk/customer/portals',
    icon: 'HelpCircleIcon',
  },
  {
    header: 'Advanced',
    permissions: {
      companyUser: ['invoicing-tryout', 'view customs-messages'],
      user: ['invoicing-tryout', 'view customs-messages'],
    },
  },
  {
    title: 'Message log',
    route: 'customs-log',
    icon: 'EyeIcon',
    permissions: {
      companyUser: ['view customs-messages'],
      user: ['view customs-messages'],
    },
  },
  {
    title: 'Invoicing',
    route: 'invoicing',
    icon: 'DollarSignIcon',
    permissions: {
      companyUser: ['invoicing-tryout'],
      user: ['invoicing-tryout'],
    },
    children: [
      {
        title: 'Try out',
        route: 'invoicing-tryout',
        icon: 'PieChartIcon',
      },
      {
        title: 'Invoices',
        route: 'invoices',
        icon: 'ActivityIcon',
      },
    ],
  },
  {
    title: 'Valid HS codes',
    route: 'valid-hs-codes',
    icon: 'FileTextIcon',
    roles: ['Feeport Admin'],
  },
]
